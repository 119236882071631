import React from 'react';
import { Link } from 'gatsby';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import img from '@src/images/404.svg';

const NotFoundPage = () => (
  <Layout className="v-404" pageName={pages.notFound.name}>
    <div className="h-content-center h-flex-center h-mt-40 h-mb-40">
      <img src={img} alt="Page not found" />
      <div>
        <h1>Page not found</h1>
        <p>It seems that you just hit a non-existing page.</p>
        <Link className="c-btn c-btn--primary" to="/">Back to home</Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
